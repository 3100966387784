import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { GameCards } from '../GameCards'
import {
  ProgressBar,
  CardEndGame,
  StageCompleteAlert,
  Menu,
  LeaveJobConfirmationCard,
  CardHowToPlay,
} from '..'

import { CardUnderstanding } from '../CardUnderstanding'

import { Dice } from './containers'

import { BoardWrapper, DiceContainer, ContainerSvgBoard } from './styles'

import { gamePropTypes } from '../../game'
import { config } from '../../game/config'
import { getConditionKeys, playerHasCondition } from '../../game/helper'
import { GameBoard } from './containers/GameBoard'

import { useHistory } from 'react-router-dom'

import {
  reportExceededLimitTurn,
  reportPlayerWin,
  reportIntentionLeaveGame,
  reportRetriveGame,
  reportReplayGame,
} from '../../services/analytics'
import { clear, get, set } from '../../services/storage'

import { FeedbackCard } from '../Cards/feedbackCard'
import { CardWithFlag } from '../Cards/cardWithFlag'
import { ShareCard } from '../Cards/ShareCard'
import { WinMedalCard } from '../Cards/WinMedalCard'
import {
  IcBrendaMedal,
  IcCrisMedal,
  IcJonathanMedal,
  IcMilenaMedal,
} from '../../assets/img/medals'

import { BandLeaveJob } from './bandLeaveJob'
import { CardMedal } from '../Account/components/medalCard'
import { dataOfMedals } from '../Account/components/medalsCard/dataOfMedals'
import { injectIntl } from 'react-intl'

export const GameTableCp = ({ G, moves, location, intl }) => {
  const { push } = useHistory()

  const [showWinMedalCard, setShowWinMedalCard] = useState(false)
  const [showMedal, setShowMedal] = useState(false)
  const [showCardHowToPlay, setShowCardHowToPlay] = useState(false)
  const [endGame, setEndGame] = useState(false)

  const [showStageCompleteAlert, setShowStageCompleteAlert] = useState({
    show: false,
    level: '',
  })

  const [concludeStep, setConcludeStep] = useState({})

  const { size } = config.board
  const { employed } = config.player.conditions

  const {
    addCondition,
    removeCondition,
    stepTile,
    rollDice,
    hideCard,
    commitPoints,
  } = moves

  const { data } = location
  const history = useHistory()

  const [showCard, setShowCard] = useState(false)
  const [categoryName, setCategoryName] = useState('')
  const [understandedCars, setUnderstandedCars] = useState([])
  const [showLeaveJobButton, setShowLeaveJobButton] = useState(false)
  const [showJobIcon, setShowJobIcon] = useState(getConditionKeys(G).includes('employed'))
  const [showLeaveJobConfirmation, setShowLeaveJobConfirmation] = useState(false)
  const [showUnderstandingCard, setShowUnderstandingCard] = useState(false)
  const [showFeedbaclCard, setShowFeedbaclCard] = useState(false)
  const [showFinalCard, setShowFinalCard] = useState(false)
  const [showShareCard, setShowSharedCard] = useState(false)
  const [isPlayAgain, setIsPlayAgain] = useState(false)

  const medals = useMemo(dataOfMedals, [])
  const [currentMedal, setCurrentMedal] = useState({})

  const currentStageGame = useMemo(retrieveStageOfPlayer, [G])
  function retrieveStageOfPlayer() {
    setUnderstandedCars(G.understandedCards)
    return G.gameStagesNames[G.gameStage]
  }

  useEffect(retrieveProgress, [])
  function retrieveProgress() {
    const gameStage = get('gameState')

    if (gameStage) {
      setConcludeStep(G.concludeStep)
      setUnderstandedCars(gameStage.understandedCars)
    }
  }

  useEffect(setCurrentConcludeStep, [G.concludeStep])
  function setCurrentConcludeStep() {
    setConcludeStep(G.concludeStep)
  }

  useEffect(saveProgress, [G, understandedCars])
  function saveProgress() {
    if (!get('token')) {
      const gameState = buildGameSave(G, understandedCars)
      set('gameState', gameState)
    } else {
      // clear('gameState')
    }
  }

  useEffect(verifyEmployedCondition, [G.conditions])
  function verifyEmployedCondition() {
    const isEmployed = getConditionKeys(G).includes('employed')
    setShowJobIcon(isEmployed)
  }

  function buildGameSave(G, understandedCars) {
    const GSave = { ...G }
    GSave.currentCard.complete = true
    GSave.boardTiles = []

    return {
      G: GSave,
      understandedCars: understandedCars,
    }
  }

  useEffect(stepTileMove, [G.animatePawn])
  function stepTileMove() {
    moves.stepTile()
    logicShowUnderstandingCards()
  }

  useEffect(setupCharacter, [])
  function setupCharacter() {
    if (G.character) {
      reportRetriveGame()
      clear('gameState')
    } else if (!data) {
      history.goBack()
    } else {
      moves.selectCharacter(data.indexCurrentCharacter)
      setShowCardHowToPlay(true)
    }
  }

  useEffect(setCurrentDataMedal, [showMedal])
  function setCurrentDataMedal() {
    if (data) setCurrentMedal(medals[data.indexCurrentCharacter])
  }

  useEffect(checkIfExceededLimitTurn, [G.turnCounter])
  function checkIfExceededLimitTurn() {
    if (G.turnCounter >= G.maxTurns) {
      setEndGame(true)
      reportExceededLimitTurn()
    }
  }

  useEffect(checkProgressToShowAlert, [G.progress])
  function checkProgressToShowAlert() {
    const { junior, middle, superior } = G.progressLevels

    if (concludeStep.junior) {
      if (G.progress >= junior.max && !concludeStep.junior.done) {
        changeStateShowAlert('junior')
      } else if (G.progress >= middle.max && !concludeStep.middle.done) {
        changeStateShowAlert('middle')
      } else if (G.progress >= superior.max && !concludeStep.superior.done) {
        doneLevel('superior')()
        reportPlayerWin()
        setEndGame(true)
      }
    }
  }

  useEffect(checkEmployedCondition, [G.conditions])
  function checkEmployedCondition() {
    const cardJobIntroductionExhibited = G.cardsAlreadyExhibited.includes(
      'jobIntroduction',
    )

    if (!cardJobIntroductionExhibited) {
      setShowJobIcon(playerHasCondition(G, 'employed'))
    }
  }

  useEffect(checkAlreadyShowJobIntroduction, [showJobIcon])
  function checkAlreadyShowJobIntroduction() {
    if (showJobIcon) {
      G.cardsAlreadyExhibited.push('jobIntroduction')
    }
  }

  function changeStateShowAlert(level, show = true) {
    if (!G.cardsAlreadyExhibited.includes(level)) {
      G.cardsAlreadyExhibited.push(level)
      setShowStageCompleteAlert({
        show,
        level,
      })
    }
  }

  useEffect(updateConcludesStep, [concludeStep])
  function updateConcludesStep() {
    moves.updateConcludeStep(concludeStep)
  }

  function doneLevel(level) {
    return () => {
      setConcludeStep({
        ...concludeStep,
        [level]: { done: true },
      })

      setShowStageCompleteAlert({
        show: false,
        level,
      })
    }
  }

  useEffect(checkConditions, [G.turnCounter, G.conditions])
  function checkConditions() {
    const employedCondition = G.conditions.filter((c) => c.key === employed.key)[0]
    setShowLeaveJobButton(
      employedCondition && G.turnCounter - 3 >= employedCondition.turn,
    )
  }

  function onJobButtonClick() {
    setShowLeaveJobConfirmation(true)
  }

  function onLeaveJobClick() {
    removeCondition({ key: 'employed' })
    setShowLeaveJobConfirmation(false)
    setShowLeaveJobButton(false)
  }

  function onCancelClick() {
    setShowLeaveJobConfirmation(false)
  }

  useEffect(activeAlertOfRefreshScreen, [currentStageGame])
  function activeAlertOfRefreshScreen() {
    window.onbeforeunload = showAlertIfRefreshPage
    return () => {
      window.onbeforeunload = null
    }
  }

  function showAlertIfRefreshPage() {
    const isLoginFacebook = get('isLoginFacebook')
    reportIntentionLeaveGame(currentStageGame)
    if (!isLoginFacebook) {
      return ''
    }
  }

  function closeCardHowToPlay() {
    setShowCardHowToPlay(false)
  }

  function getCurrentCategory() {
    return config.board.tiles[G.pawnTilePosition]
  }

  function alreadyExhibitedUnderstendCard(category) {
    return G.understandedCards.some((card) => card === category)
  }

  function logicShowUnderstandingCards() {
    if (G.pawnTilePosition >= 0 && G.understandedCards.length < 5) {
      const category = getCurrentCategory()

      if (!alreadyExhibitedUnderstendCard(category)) {
        setCategoryName(category)
        setShowUnderstandingCard(true)
      }
    }
  }

  useEffect(askAuthorizationToOpenCard, [G.showCard])
  function askAuthorizationToOpenCard() {
    if (G.showCard) {
      const category = getCurrentCategory()
      setShowCard(alreadyExhibitedUnderstendCard(category))
    }
  }

  function closeCard() {
    hideCard()
    setShowCard(false)
  }

  function closeUnderstandingCard() {
    setUnderstandedCars([...understandedCars, ...[categoryName]])
    G.understandedCards.push(categoryName)
    setShowUnderstandingCard(false)
    setShowCard(true)
  }

  function clearUnderstandingCard() {
    setUnderstandedCars([])
  }

  function goToLandingPage() {
    window.location.replace('https://es.gamevuca.org')
  }

  function playAgain() {
    reportReplayGame()
    push('/home')
  }

  function openFeedbackCard(playAgain) {
    clearUnderstandingCard()
    setIsPlayAgain(playAgain)
    setEndGame(false)
    setShowWinMedalCard(false)
    setShowFeedbaclCard(true)
  }

  function closeFeedbackCard() {
    setShowFeedbaclCard(false)
    setShowFinalCard(true)
  }

  const [openEndGame, setOpenEndGame] = useState(false)

  function openShareCard(endG) {
    setOpenEndGame(endG)
    setShowWinMedalCard(false)
    setEndGame(false)
    setShowSharedCard(true)
  }

  function closeSharedCard() {
    setShowSharedCard(false)

    if (openEndGame) {
      setEndGame(true)
    } else {
      setShowWinMedalCard(true)
    }
  }

  function openWinMedalCard() {
    setEndGame(false)
    setShowWinMedalCard(true)
  }

  function openMedalCard() {
    setShowWinMedalCard(false)
    handleShowMedal(!showMedal)
  }

  function closeMedalCard() {
    setShowWinMedalCard(true)
    handleShowMedal(!showMedal)
  }

  function getCurrentIconMedal() {
    const characterMedals = {
      Brenda: IcBrendaMedal,
      Jonathan: IcJonathanMedal,
      Cris: IcCrisMedal,
      Milena: IcMilenaMedal,
    }

    return characterMedals[G.character.name]
  }

  function handleShowMedal() {
    setShowMedal(!showMedal)
  }

  return (
    <BoardWrapper data-test="GameTableWrapper" gameStage={G.gameStage}>
      <div className="containerHeader">
        <Menu G={G} />
        <ProgressBar G={G} />
        {showJobIcon && (
          <BandLeaveJob
            G={G}
            onJobButtonClick={onJobButtonClick}
            showJobIcon={showJobIcon}
            showLeaveJobButton={showLeaveJobButton}
          />
        )}
      </div>

      <section className="gameSpace">
        <DiceContainer>
          <Dice G={G} rollDone={rollDice} />
        </DiceContainer>

        <ContainerSvgBoard>
          <GameBoard G={G} size={size} onPawnStepAnimationEnd={stepTile} />
        </ContainerSvgBoard>
      </section>

      {showLeaveJobConfirmation && (
        <LeaveJobConfirmationCard
          onLeaveJobClick={onLeaveJobClick}
          onCancelClick={onCancelClick}
        />
      )}

      {showCard && (
        <GameCards
          currentCard={G.currentCard}
          addCondition={addCondition}
          removeCondition={removeCondition}
          close={closeCard}
          givePoint={commitPoints}
          character={G.character}
          G={G}
        />
      )}

      {endGame && G.character && (
        <CardEndGame
          character={G.character}
          maxPoints={G.progressLevels.superior.max}
          playerWin={concludeStep.superior.done}
          openFeedBack={openFeedbackCard}
          openWinMedalCard={openWinMedalCard}
          openShareCard={openShareCard}
        />
      )}

      {showWinMedalCard && (
        <WinMedalCard
          G={G}
          openFeedBack={openFeedbackCard}
          openShareCard={openShareCard}
          character={G.character.name}
          openMedalCard={openMedalCard}
          Icon={getCurrentIconMedal()}
        />
      )}

      {showMedal && (
        <CardMedal
          closeMedalCard={closeMedalCard}
          currentMedal={currentMedal}
          Icon={getCurrentIconMedal()}
        />
      )}

      {showFeedbaclCard && (
        <FeedbackCard
          closeFeedbackCard={closeFeedbackCard}
          onCancelClick={goToLandingPage}
        />
      )}

      {showFinalCard && (
        <CardWithFlag
          isOnlyInfo={true}
          onConfirmClick={isPlayAgain ? playAgain : goToLandingPage}
          title={intl.formatMessage({ id: 'game.toEnd' })}
          paragraph={
            intl.formatMessage({ id: 'game.toThank' }) +
            intl.formatMessage({
              id: isPlayAgain ? 'game.start.newgame' : 'game.question.empty',
            })
          }
          confirmText={intl.formatMessage({ id: isPlayAgain ? 'start' : 'exit' })}
        />
      )}

      {showUnderstandingCard && (
        <CardUnderstanding
          categoryName={categoryName}
          closeUnderstandingCard={closeUnderstandingCard}
        />
      )}

      {showStageCompleteAlert.show && (
        <StageCompleteAlert
          characterName={G.character.name}
          nameOfCompleteLevel={showStageCompleteAlert.level}
          confirmCompleteStage={doneLevel}
        />
      )}

      {showCardHowToPlay && (
        <CardHowToPlay
          closeCardHowToPlay={closeCardHowToPlay}
          back={() => history.goBack()}
        />
      )}

      {showShareCard && <ShareCard back={closeSharedCard} />}
    </BoardWrapper>
  )
}

const { G } = gamePropTypes
GameTableCp.propTypes = {
  ...gamePropTypes,
  G,
  int: PropTypes.object,
}

export const GameTable = injectIntl(GameTableCp)
